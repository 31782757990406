let projects = [
	{
		id: 1,
		name: "Lieferplatz",
		description:
			"Lieferplatz is a web-based food delivery platform designed for both customers and restaurants to streamline the process of ordering food online. Developed using Java with Spring Boot, it provides an intuitive interface for managing orders.",
		src: "LieferplatzLoginPage.png",
		tech: "HTML, CSS, ThymeLeaf, SQlite, Springboot",
		/*link: "https://hackwith.codechefvit.com/",*/
		git: "https://github.com/abdhatim/LieferPlatz",
	},
	/*{
		id: 2,
		name: "Common Entry Test",
		description:
			"Common Entry Test is an online test portal that helps make applying to clubs and chapters in VIT much easier and hassle free. The website had more than 67,000+ page views and 3000+ students gave tests through the portal in the span of a month.",
		src: "cet.png",
		tech: "ReactJS, Material UI, ExpressJS, MongoDB, AWS, etc.",
		link: "https://cet-portal.codechefvit.com/",
		git: "https://github.com/CodeChefVIT/cetFrontend",
	},
	{
		id: 3,
		name: "Quizzie",
		description:
			"Quizzie is an open source, fully professional quizzing platform. It makes it very easy to create and give quizzes. I worked mainly on the frontend part.",
		src: "quizzie.png",
		tech: "React, Material UI, Axios, ChartsJS, and many more libraries.",
		link: "https://quizzie.codechefvit.com",
		git: "https://github.com/Sarthakbh321/Quizzie",
	},
	{
		id: 4,
		name: "HotSpot",
		description:
			"HotSpot is an Android and iOS app, which displays a heat-map showing the strength of users' data connection.",
		src: "hotspot.png",
		tech: "React Native, Axios, Expo CLI, etc.",
		git: "https://github.com/Sarthakbh321/HotSpot",
	},
	{
		id: 5,
		name: "Spidey",
		description:
			"Spidey is a 2D game. Play as a spider and eat as many bugs ans you can!",
		src: "spidey.png",
		tech: "Java, LibGDX library",
		git: "https://github.com/Sarthakbh321/Spidey-Game",
	},
	{
		id: 6,
		name: "View all on my GitHub",

		src: "github.svg",
		link: "https://github.com/Sarthakbh321",
		git: "https://github.com/Sarthakbh321",
	},*/
]; 

export default projects;
